/*** App styles ***/
html { width: 100%; height: 100%; }
body { width: 100%; height: 100%; color: black; font-size: 18px; line-height: 1.2; font-family: 'Plus Jakarta Sans', sans-serif; background: #f5f5f5; }
#root { display: flex; width: 100%; height: 100%; }

/*** Animations ***/
@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.spin {
    animation: spin 2s infinite linear;
}

.MuiMenu-paper.MuiPopover-paper { border-radius: 10px; box-shadow: 0px 3px 6px #00000021; border: 1px solid #00000021; }
.pulsingButton { box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
    -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    transition: all 500ms ease-in-out !important;
}
.pulsingButton:hover { -webkit-animation: none; -moz-animation: none; -ms-animation: none; animation: none; color: #ffffff; }

@-webkit-keyframes pulsing {
    to {
        box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);
    }
}

@-moz-keyframes pulsing {
    to {
        box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);
    }
}

@-ms-keyframes pulsing {
    to {
        box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);
    }
}

@keyframes pulsing {
    to {
        box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);
    }
}

@media screen and (max-width: 767px) {
    .jobsScreen { background: white; }
    .dark.jobsScreen { background: #202020; }
}

/*** Gleap styles ***/
@media screen and (max-width: 768px) {
    .bb-feedback-button { bottom: 72px !important; z-index: 20 !important; }
}

/*** Mapycz styles ***/
.smap-suggest { top: 53px; }
.smap-suggest .item,
.dark .smap-suggest .item { color: black; }


.styledScrollbar::-webkit-scrollbar {
     width: 6px;
     height: 6px;
 }
.styledScrollbar::-webkit-scrollbar-track {
     border-radius: 10px;
     background: rgba(0,0,0,0.1);
 }
.styledScrollbar::-webkit-scrollbar-thumb{
     border-radius: 10px;
     background: rgba(0,0,0,0.2);
 }
.styledScrollbar::-webkit-scrollbar-thumb:hover{
     background: rgba(0,0,0,0.3);
 }
.styledScrollbar::-webkit-scrollbar-thumb:active{
     background: rgba(0,0,0,.5);
 }

.styledScrollbar {
    scrollbar-color: rgba(0,0,0,0.1) rgba(0,0,0,0.2);
    scrollbar-width: thin;
}

.dark .styledScrollbar::-webkit-scrollbar-track {
    background: rgba(255,255,255,0.1);
}
.dark .styledScrollbar::-webkit-scrollbar-thumb{
    background: rgba(255,255,255,0.2);
}
.dark .styledScrollbar::-webkit-scrollbar-thumb:hover{
    background: rgba(255,255,255,0.3);
}
.dark .styledScrollbar::-webkit-scrollbar-thumb:active{
    background: rgba(255,255,255,.5);
}

.dark .styledScrollbar {
    scrollbar-color: rgba(255,255,255,0.1) rgba(255,255,255,0.2);
    scrollbar-width: thin;
}
